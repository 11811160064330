.navbar {
  width: 100%;
  background-color: white;
  z-index: 1000; /* Ensure the navbar appears above other content */
  padding: 0px !important;
}
.dropdown {
  position: relative;
  display: inline-block;
}

.dropdown-menu {
  display: none;
  position: absolute;
  background-color: #f9f9f9;
  width: 260px;
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.2);
  z-index: 1;
}

.dropdown-menu a {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
}

.dropdown:hover .dropdown-menu {
  display: block;
}



.navbar-toggle {
  cursor: pointer;
}

.navbar-toggle span {
  background: #333;
  display: block;
  height: 2px;
  margin: 5px;
  transition: 0.4s;
}
.navbar-fixed {
  position: fixed;
  top: 0;
  left: 0;
  padding: 0px !important;

}
.navbar-logo span {
  font-size: 25px;
  font-weight: 700;
  color: #014bab;
}
.navbar-container {

  justify-content: space-between;
  display: flex;
  align-items: center;
}

.navbar-logo img {
  width: 95px;
}
.logo p {
  font-size: 16px;
  font-weight: 600;
  padding:0px;
}

.navbar-links {
  justify-content: space-between;
  display: flex;
  align-items: center;
}

.navbar-links a {
  color: #014bad;
  text-decoration: none;
  margin: 0 8px;
  font-size: 18px;
  font-weight: 700;
}
.navbar-links a:hover{
  color: #9dce5b;
  text-decoration: none;
  margin: 0 15px;
  font-size: 18px;
  font-weight: 700;
}


.navbar-toggle {
  display: none;
  flex-direction: column;
  cursor: pointer;
}

.navbar-toggle span {
  height: 2px;
  width: 25px;
  background-color: #1458b3;
  margin: 3px 0;
}
@media only screen and (max-width: 600px) {
  .navbar-logo img {
    width: 90px;
  }
  .logo p {
    font-size: 10px;
    font-weight: 700;
    padding:0px;
  }
  .navbar-logo span {
    font-size: 20px;
    font-weight: 700;
    color: #014bab;
  }
  
}

@media (max-width: 768px) {
  .navbar-links {
    display: none;
    flex-direction: column;
    position: absolute;
    top: 90px;
    right: 0;
    width: 100%;
    background-color: #014bad;
    padding: 15px 20px;
    box-sizing: border-box;
    z-index: 4;
  }
  .navbar-links a {
    color: white;
    font-size: 19px;
    font-weight: 600;
  }
  

  .navbar-links.active {
    display: flex;
  }

  .navbar-toggle {
    display: flex;
  }
}
