/* Default styles */
.Service-header {
    position: relative;
}

.Service-header::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5); 
    z-index: 1; 
}

.Service-header {
    background-image: url('../assests/images/AboutHeader.jpg');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    height: 400px; /* Adjust as needed for desktop */
    position: relative;
}

.header-content {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    color: white;
    z-index: 2; /* Ensure the content is on top of the overlay */
}

.service-content {
    padding-top: 30px;
    padding-bottom: 30px;
}

.service-content h3 {
    font-size: 20px;
    font-weight: 600;
    padding-top: 15px;
    text-align: left;
    color: #fe540b;
}

.service-content img {
    width: 100%;
    border-radius: 20px;
    height: 250px;
}

.service-content p {
    font-size: 15px;
    font-weight: 400;
    padding-top: 15px;
    text-align: left;
    color: black;
}

.service h2 {
    align-items: center;
    justify-content: center;
    color: #034bac;
    font-weight: 700;
    font-size: 37px;
    padding-top: 30px;
}
.new-services h5 {
    align-items: center;
    justify-content: center;
    color: #034bac;
    font-weight: 700;
    font-size: 22px;
    padding-top: 30px;
    padding-bottom: 20px;
}
.service-sub-para {
    font-size: 12px !important;
}
.maps h2 {
    padding-top: 20px;
    align-items: center;
    justify-content: center;
    color: #034bac;
    font-weight: 700;
    font-size: 37px;
  }
.maps p {
    font-size: 18px;
    font-weight: 500;
  }
.new-services p span {
    font-weight: 600;
    font-size: 20px;
}
.maps p i {
    background-color: #034bac;
    font-size: 12px;
    color: white;
    padding: 4px;
    border-radius: 50%;
      margin-right: 8px;
  }

/* Responsive styles */
@media only screen and (max-width: 768px) {
    .Service-header {
        height: 300px; /* Adjust as needed for smaller screens */
    }
    
    .service h2 {
        font-size: 30px; /* Adjust font size for smaller screens */
    }
}

@media only screen and (max-width: 576px) {
    .Service-header {
        height: 200px; /* Adjust as needed for even smaller screens */
    }
    
    .service h2 {
        font-size: 25px; /* Further adjust font size for smaller screens */
    }
}
