.footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: white;
    padding: 20px;
  }
  
  .footer__left {
    text-align: left;
  }
  
  .footer__center {
    text-align: center;
  }
  .footer__center {
    font-weight: 400;
  }

  .footer__logo {
    width: 90px; /* Adjust size as needed */
    height: auto; /* Maintain aspect ratio */
    border-radius: 30px;
  }
  
  .footer__links {
    list-style-type: none;
    padding: 0;
  }
  
  .footer__links li {
    margin-bottom: 10px;
  }
  
  .footer__links li a {
    color: white;
    text-decoration: none;
  }
  
  .footer__social {
    list-style-type: none;
    padding: 0;
  }
  
  .footer__social li {
    display: inline;
    margin-left: 10px;
  }
  
  .footer__social li a {
    color: white;
    text-decoration: none;
  }
  .footer__left p{
   font-size: 13px;
  }
  .right {
    align-items: center;
    justify-content: center;
    
  }
  
  /* Media Query for Responsiveness */
  @media screen and (max-width: 768px) {
    .footer {
      flex-direction: column;
      text-align: center;
    }
    .footer__left, .footer__center, .footer__right {
      margin-bottom: 20px;
    }
  }
  