.domestic-header {
    position: relative;
}

.domestic-header::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5); 
    z-index: 1; 
}

.domestic-header {
    background-image: url('../assests/images/domesticbg.jpg');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    height: 400px; /* Adjust as needed for desktop */
    position: relative;
}