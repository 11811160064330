/* Service.css */
  /* Contact Information Styles */
  .contact-info {
    padding: 10px;
    text-align: center;
    margin: 20px 0;
  }
  
  .contact-info h2 {
    padding-top: 30px;
    margin-bottom: 10px;
    color: #81b522;
  }
  
  .contact-info p {
    margin: 0;
    color: black;
  }
  
  /* Career Query Form Styles */
  /* Career Query Form Styles */
.career-form {
    background-color: #fff;
    border-radius: 5px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    padding: 30px;
    max-width: 600px; /* Set a max-width to limit the width of the form */
    margin: 20px auto; /* Center the form horizontally */
  }
  
  .career-form h2 {
    margin-bottom: 20px;
    color: #014aac;
    font-weight: 600;
  }
  
  .career-form input[type="text"],
  .career-form input[type="email"],
  .career-form input[type="tel"],
  .career-form textarea,
  .career-form button,
  .career-form input[type="file"] {
    width: 100%;
    padding: 10px;
    margin-bottom: 15px;
    border: 1px solid #ccc;
    border-radius: 5px;
  }
  
  .career-form textarea {
    height: 100px;
  }
  
  .career-form button {
    background-color: #014aac;
    color: white;
    border: none;
    cursor: pointer;
  }
  
  .career-form button:hover {
    background-color: #45a049;
  }
  
  /* Media Queries for Responsiveness */
  @media screen and (max-width: 768px) {
    .career-form {
      padding: 15px;
    }
  }
  
  @media screen and (max-width: 576px) {
    .career-form input[type="text"],
    .career-form input[type="email"],
    .career-form input[type="tel"],
    .career-form textarea,
    .career-form button,
    .career-form input[type="file"] {
      padding: 8px;
    }
  }