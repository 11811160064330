.about-header {
    position: relative;
  }
  
  .about-header::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5); 
  }
  
  .about-header {
    background-image: url('../assests/images/HeaderImage3.jpg');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    height: 400px; /* Adjust as needed for desktop */
    position: relative;
  }
  .Testing-methods h4 {
    font-size: 25px;
    font-weight: 700;
    color: #034bac;
  }
  .map .about-para {
    font-size: 18px;
    font-weight: 400;
    padding-bottom: 30px;
  }
  .tool-content h3{
    align-items: center;
  justify-content: center;
  color: #034bac;
  font-weight: 700;
  font-size: 32px;
  padding-top: 30px;
  animation: fadeInLeft 2s ease-in-out;
}
 .tool-content img {
  border-radius: 30px;
}
.clients-line {
  width: 250px;
}
.Testing-methods p i {
  background-color: #034bac;
  font-size: 12px;
  color: white;
  padding: 4px;
  border-radius: 50%;
    margin-right: 8px;
}
.tool-list p i {
  background-color: #034bac;
  font-size: 12px;
  color: white;
  padding: 4px;
  border-radius: 50%;
    margin-right: 8px;
}
.tool-line {
  width: 200px;
  height: 7px;
}
  
  .header-content {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    color: white;
    z-index: -1; /* Ensure the content is on top of the overlay */
  }
  
  .header-content h1 {
    font-size: 3.5rem;
    margin-bottom: 10px;
    font-weight: 700;
    z-index: -1;
  }
  
  .about-say h2 {
    align-items: center;
    justify-content: center;
    color: #014bab;
    font-weight: 700;
    font-size: 37px;
    padding-bottom: 20px;
    padding-top: 30px;
    animation: fadeInLeft 2s ease-in-out;
  }
  
  .about-say p {
    align-items: center;
    justify-content: center;
    color: black;
    font-weight: 600;
    font-size: 17px;
    text-align: justify;
  }
.our-mission-title {
    align-items: center;
    justify-content: center;
    color: white;
    font-weight: 700;
    font-size: 37px;
    padding-bottom: 20px;
    padding-top: 30px;
}
  
  .vision-section {
    margin-top: 40px; /* Adjust as needed */
    padding: 20px;
    background-color: #f9f9f9; /* Example background color */
    border-radius: 8px; /* Example border radius */
  }
  .back-ground-color {
    background-color: #e9e9e9;
  }
  .vision-section h2 {
    text-align: justify;
    color: #014bab;
    font-weight: 700;
    font-size: 27px;
    padding-bottom: 20px;
    padding-top: 30px;
  }
  .vision-section h3 {
    text-align: justify;
    color: white;
    font-weight: 700;
    font-size: 27px;
    padding-bottom: 20px;
    padding-top: 30px;
  }
  .vision-section .para {
    text-align: justify;
    font-size: 16px; /* Example font size */
    color: white; /* Example color */
  }
  
  .background-color {
    background-color: #005eb8;
    border-radius: 20px;
  }
  
  .vision-section p {
    text-align: justify;
    font-size: 16px; /* Example font size */
    color: black; /* Example color */
  }
  
  /* Responsive Styles */
  @media only screen and (max-width: 768px) {
    .about-header {
      height: 300px; /* Adjust height for smaller screens */
    }
  
    .header-content {
      top: 40%; /* Adjust position for smaller screens */
    }
  
    .header-content h1 {
      font-size: 2.5rem; /* Adjust font size for smaller screens */
    }
  }
  
  @media only screen and (max-width: 576px) {
    .about-header {
      height: 200px; /* Adjust height for even smaller screens */
    }
  
    .header-content {
      top: 30%; /* Adjust position for even smaller screens */
    }
  
    .header-content h1 {
      font-size: 1.5rem; /* Adjust font size for even smaller screens */
    }
  }
  