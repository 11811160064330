.Projects-header {
    position: relative;
}

.Projects-header::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5); 
    z-index: 1; 
}

.Projects-header {
    background-image: url('../assests/images/project-bg.jpeg');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    height: 400px; /* Adjust as needed for desktop */
    position: relative;
}

.header-content {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    color: white;
    z-index: 2; /* Ensure the content is on top of the overlay */
}

.Projects-content {
    padding-top: 30px;
    padding-bottom: 30px;
}

.Projects-content h3 {
    font-size: 20px;
    font-weight: 600;
    padding-top: 15px;
    text-align: left;
    color: #fe540b;
}

.Projects-content img {
    width: 100%;
    border-radius: 20px;
    height: 250px;
}

.Projects-content p {
    font-size: 15px;
    font-weight: 400;
    padding-top: 15px;
    text-align: left;
    color: black;
}
/* Project Items Styling */
.projects-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 20px;
  margin-top: 30px;
}

.project-item {
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 8px;
  padding: 20px;
  text-align: center;
  flex: 1 1 300px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s;
}

.project-item:hover {
  transform: scale(1.05);
}

.project-item img {
  width: 100%;
  height: auto;
  border-radius: 8px;
}

.project-item h3 {
  font-size: 24px;
  margin-top: 15px;
}

.project-item p {
  font-size: 16px;
  color: #777;
}
/* Projects Section Styling */
.container.service {
    padding: 40px 20px;
  }
  
  .container.service h2 {
    font-size: 32px;
    margin-bottom: 20px;
    color: #034bac;
  }
  
  .clients-line {
    width: 150px;
    margin: 0 auto;
  }
  
  /* Project List Styling */
  .projects-list ul {
    list-style-type: none;
    padding: 0;
  }
  
  .projects-list li {
    display: flex;
    align-items: center;
    padding: 20px;
    border-bottom: 1px solid #ddd;
  }
  
  .projects-list img {
    width: 150px;
    height: auto;
    border-radius: 8px;
    margin-right: 20px;
  }
  
  .project-info h3 {
    font-size: 18px;
    margin-bottom: 5px;
    padding-left: 20px;
  }
  .icon-style {
    color: #034bac; /* Define the color */
    font-size: 20px; /* Set icon size */
    margin-right: 20px;
  }
  
  /* Additional styling for alignment */
  .project-icon,
  .service-icon {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
  }
  
  .project-info p {
    font-size: 16px;
    color: #777;
    padding-left: 20px;

  }
  
  /* WhatsApp Icon Styling */
  .whatsapp-icon {
    position: fixed;
    bottom: 20px;
    right: 20px;
    cursor: pointer;
  }
  
  .whatsapp-icon-img {
    width: 50px;
    height: 50px;
  }
  
  /* Responsive Design */
  @media (max-width: 768px) {
    .projects-list li {
      flex-direction: column;
      align-items: flex-start;
    }
  
    .projects-list img {
      margin-bottom: 10px;
    }
  }
  
