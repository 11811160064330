/* AddressBar.css */
.address-bar-container {
    background-color: #014bad;
}
.address-bar {
    display: flex;
    flex-direction: column; /* Change flex direction to column for responsiveness */
    padding: 10px;
  }
  
  .left,
  .right {
    display: flex;
    align-items: center;
    margin-bottom: 10px; /* Add margin to separate email and phone sections */
  }
  .size {
    font-size: 30px;
  }
  
  .icon {
    margin-right: 10px;
    color: white; /* Increase margin between icon and text */
  }
  .icons .icon {
    padding-left: 10px;
    color: white;
  }
  .text {
    font-family: 'Arial', sans-serif;
    font-size: 14px;
    color: white;
  }
  
  @media screen and (min-width: 768px) {
    /* Apply different styles for larger screens (e.g., tablets and desktops) */
    .address-bar {
      flex-direction: row; /* Change back to row for larger screens */
      justify-content: space-between; /* Align items to the start and end */
    }
  
    .left,
    .right {
      margin-bottom: 0; /* Reset margin for larger screens */
    }
  }
  