.carousel-content {
    position: relative;
    width: 100%;
    height: 100%;
    z-index: 0;
  }
 .carousel-content::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5); 
  }

  .carousel-content img {
    width: 100%;
    max-height: 600px;
    object-fit: cover; 
  }

  .centered-content {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    color: #ffffff;
    z-index: 2;
    padding: 20px;
  }
  
  .centered-content h1 {
    font-weight: bold;
    font-size: 55px;
    margin-bottom: 10px;
    font-weight: 700;
    text-align: left;
}
  @keyframes slideLeftToRight {
  0% {
    transform: translateX(-100%);
  }
  100% {
    transform: translateX(100%);
  }
}
  .centered-content p {
    font-size: 22px;
    line-height: 1.5;
    font-weight: 500;
    text-align: left;

  }
  
  /* Responsive styles */
  @media screen and (max-width: 768px) {
    .centered-content h1 {
        font-weight: 600;
        font-size: 30px;
        color: white;
      }
      .centered-content p {
        font-size: 15px; /* Example font size for slide description */
      }
  }
  
  @media screen and (max-width: 576px) {
    /* Additional responsive styles for even smaller screens */
  }
  /* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
  .centered-content h1 {
    font-weight: bold;
    font-size: 16px;
    margin-bottom: 10px;
  }
  
  .centered-content p {
    font-size: 10px;
    line-height: 1.5;
  }
  .logo a  {
    width: 140px;
  }
  .offer-title h2 {
    font-size: 25px !important;
  }

  
  
} 