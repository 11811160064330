.home-container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 20px; /* Add padding to provide spacing on the sides */
  }
  .offer-title h2 {
    line-height: 38px;
    padding: 0;
    margin: 0 0 20px;
    font-size: 40px;
    margin-bottom: 20px;
    color: #014bab;
    text-align: justify;
    font-size: 37px;
    font-weight: 700;
  }
  .service-img {
    transition: transform 0.5s ease;
  }
  .distance {
    padding-top: 30px;
    padding-bottom: 30px;
  }
  .service-img:hover {
    transform: perspective(1000px) rotateY(10deg);
  }
  .service-line {
    width: 20%;
    height: 7px;
  }
  .service-line2 {
    width: 20%;
    height: 7px;
  }
  .clients-line {
    width: 180px;
    height: 7px;
  }
  
  .padding-top {
    padding-top: 10px;
  }
    .offer-title h6 {
    padding: 0;
    margin: 0 0 20px;
    font-size: 40px;
    margin-bottom: 20px;
    color: #fe540b;
    text-align: justify;
    font-size: 25px;
    font-weight: 600;
  }
  .up-arrow {
    position: fixed;
    bottom: 100px;
    right: 40px;
    background-color: #034bac;
    color: #fff;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.3s, visibility 0.3s;
}

.up-arrow.show {
    opacity: 1;
    visibility: visible;
}

.up-arrow i {
    font-size: 20px;
}


  .offer-title p {
    line-height: 28px;
    padding: 0;
    margin: 0 0 20px;
    font-size: 40px;
    margin-bottom: 20px;
    color: black;
    text-align: justify;
    font-size: 17px;
  }
  .offer-title .Running-projects-para {
    line-height: 28px;
    padding: 0;
    margin: 0 0 20px;
    font-size: 40px;
    margin-bottom: 20px;
    color: #8c8c8c;
    text-align: justify;
    font-size: 17px;
  }
  .latest-allowesment {
    background-color: #e9e9e9;
  }

  .columns-container {
    display: flex;
    flex-wrap: wrap; /* Allow columns to wrap to the next line on smaller screens */
    margin-top: 20px;
  }
  
  .column {
    flex-basis: 100%; /* Each column takes up the full width by default */
    box-sizing: border-box; /* Include padding and border in the width */
  }
  .service-title h2 {
    align-items: center;
    justify-content: center;
    color:  #034bac;
    font-weight: 700;
    font-size: 37px;
    padding-top: 30px;
  }

  .international-clients h2 {
    align-items: center;
    justify-content: center;
    color: #034bac;
    font-weight: 700;
    font-size: 37px;
    padding-top: 10px;
  }
  
  .team-member-sections h2 {
    padding: 25px;
    align-items: center;
    justify-content: center;
    color: #fe540b;
    font-weight: 700;
    font-size: 37px;
  }
  .map h2 {
    padding-top: 40px;
    align-items: center;
    justify-content: center;
    color: #034bac;
    font-weight: 700;
    font-size: 37px;
  }
  .map p {
    font-size: 20px;
    font-weight: 600;
  }
  .domestic-clients h2 {
    align-items: center;
    justify-content: center;
    color: #fe540b;
    font-weight: 700;
    font-size: 37px;
    padding-bottom: 20px;
  }
  .domestic-clients {
    background-color: #e9e9e9;
    padding: 40px;
  }
  .service-sub-title h3 {
    padding-top: 10px;
    padding-bottom: 10px;
    font-size: 20px;
    color: #014aac;
    text-align: left;
    font-weight: 700;
  }
  .service-sub-title img {
    border-radius: 20px;
    width: 100%;
    height: 250px;
    transition: transform 0.5s ease;
  }
  .service-sub-title {
    padding: 10px;
  }
  .home-container {
    position: relative;
  }
  .international-clients-images img {
    width: 80%;
    height: 150px;
    padding-bottom: 20px;
  }
  .whatsapp-icon {
    position: fixed;
    bottom: 20px;
    right: 20px;
    z-index: 9999;
  }
  
  .whatsapp-icon-img {
    width: 70px;
    height: 70px;
    cursor: pointer;
  }
  .team-member img {
    width: 100%;
    height: 250px;
    border-radius: 20px;
  }
  .service-sub-title p {
    font-size: 16px;
    color: black;
    font-size: 800;
    text-align: left;
  }
  .brand-carousel-container {
    max-width: 1000px; /* Adjust max-width as needed */
    margin: 0 auto; /* Center the carousel horizontally */
}

/* Brand image */
.brand-image {
    width: 100%;
    height: 100px;
    border-radius: 5px; /* Add border radius for rounded corners */
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.1); /* Add shadow effect */
    transition: transform 0.3s ease; /* Add smooth transition effect */
}
.customer-review {
    background-color: #e9e9e9;
    padding: 30px;
    margin-top: 50px;
}
.background-review {
  background-color: #054dac;
  border-radius: 30px;
}
.customer-review-images img {
  border-radius: 30px;
  width: 100%;
  height: auto;
}
.review-content h2 {
  color: white;
  font-weight: 600;
  padding-top: 30px;
}
.review-content p {
  color: white;
  font-weight: 600;
}
.customer-review h2 {
    color: #054dac;
    font-size: 35px;
    font-weight: 700;
}
.customer-review p {
    color: #fe540b;
    font-size: 15px;
    font-weight: 400;
}
.customers h3 {
    color: white;
    font-size: 35px;
    font-weight: 700;
}
.customers .destination {
    color: yellow;
    font-size: 15px;
    font-weight: 600;
}
.customers .explanation {
    color: white;
    font-size: 12px;
    font-weight: 500;
    text-align: justify;
    padding: 10px;

}
.team-member-details {
    align-items: self-start;
}
.map h2 {
    align-items: center;
    justify-content: center;
    color: #034bac;
    font-weight: 700;
    font-size: 37px;
}
/* Hover effect */
.brand-image:hover {
    transform: scale(1.05); /* Increase size on hover */
}
  /* Brand carousel container */
.brand-carousel-container {
    max-width: 1000px; /* Adjust max-width as needed */
    margin: 0 auto; /* Center the carousel horizontally */
}
.team-member h3{
     text-align: center;
     padding-top: 20px;
     color: #054dac;
     font-weight: 700;
}
.team-member h6{
    font-size: 14px;
    text-align: center;
    color: #054dac;
    font-weight: 700;
}
.team-member p{
    text-align: center;
    color: #fe540b;
    font-weight: 600;
}

/* Brand image */
.brand-image {
    width: 100%;
    height: auto;
    border-radius: 5px; /* Add border radius for rounded corners */
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.1); /* Add shadow effect */
    transition: transform 0.3s ease; /* Add smooth transition effect */
}

/* Brand name */
.brand-name {
    text-align: center; /* Center the text */
    font-size: 16px; /* Adjust font size as needed */
    margin-top: 5px; /* Add spacing between image and name */
    color: #333; /* Set color */
    font-weight: bold; /* Make the font bold */
}

/* Hover effect */
.brand-image:hover {
    transform: scale(1.05); /* Increase size on hover */
}
.copy-rights {
    background-color: #014aac;
}
.copy-rights p{
  text-align: center;
    color: white;
    padding: 10px;
}

.copy-rights a{
  color: white;
  text-decoration: none;
}
  @media screen and (min-width: 768px) {
    .column {
      flex-basis: calc(50% - 20px); /* Each column takes up 50% of the container width with spacing */
      margin: 0 10px; /* Add margin between columns */
    }

  }

  
  @media screen and (min-width: 1200px) {
    .column {
      flex-basis: calc(33.33% - 20px); /* Each column takes up 1/3 of the container width with spacing */
    }
  }
  

  img {
    max-width: 100%;
    height: auto;
  }
  



 