/* Carrer.css */

/* Contact Information */
.contact-information {
  margin-bottom: 30px;
}

.contact-information h2 {
  text-align: justify;
  padding-top: 30px;
  color: #81b522;
  font-weight: 600;
  font-size: 30px;
  margin-bottom: 10px;
}
.contact-information {
  background-color: #e9e9e9;
}

.contact-information p {
  text-align: justify;
  font-size: 16px;
  margin-bottom: 5px;
}

/* Career Form */
.career-form {
  margin-bottom: 30px;
}

.career-form h2 {
  font-size: 24px;
  margin-bottom: 20px;
}

.career-form input[type="text"],
.career-form input[type="email"],
.career-form input[type="tel"],
.career-form textarea,
.career-form input[type="file"],
.career-form button[type="submit"] {
  width: 100%;
  padding: 15px;
  margin-bottom: 15px;
  border: 1px solid #ccc;
  border-radius: 8px;
  font-size: 16px;
  box-sizing: border-box;
}

.career-form textarea {
  resize: vertical;
}

.career-form input[type="file"] {
  cursor: pointer;
}

.career-form button[type="submit"] {
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  font-size: 18px;
  transition: background-color 0.3s;
}

.career-form button[type="submit"]:hover {
  background-color: #0056b3;
}

/* Responsive Design */
@media (max-width: 768px) {
  .container {
    padding: 10px;
  }

  .career-form input[type="text"],
  .career-form input[type="email"],
  .career-form input[type="tel"],
  .career-form textarea,
  .career-form input[type="file"],
  .career-form button[type="submit"] {
    padding: 10px;
    font-size: 14px;
  }

  .contact-information p,
  .contact-information h2 {
    font-size: 18px;
  }
}
